import React from "react";
import clsx from "clsx";
import { CardListLayout } from "./layouts/cardlist-layout";
import type { SimplePortableText } from "~/types/sanity-schema";
import { EventCard } from "./events/event-card";
import type { EventCardItem } from "./events/event-card";
import { Spacer } from "./ui/spacer";
import { Button } from "./ui/button";
import { spTrackWebInteraction } from "~/utils/tracking";

interface Props {
	tagline?: string;
	subtitle?: SimplePortableText;
	title: string;
	items: Array<EventCardItem>;
	backgroundColor?: string;
	align: "center" | "left";
	loadMoreText?: string;
	limit?: number;
}

const DEFAULT_PAGE_SIZE = 3;

export function EventList({
	tagline,
	title,
	subtitle,
	items,
	align,
	loadMoreText = "Load more",
	limit = DEFAULT_PAGE_SIZE,
}: Props) {
	// If item length is 1 or 2, we use flex layout so we can center the items
	// else use grid layout
	const shouldUseGridLayout = items.length >= 3;

	const [indexToShow, setIndexToShow] = React.useState(limit);

	const visibleEvents = items.slice(0, indexToShow);

	const hasMore = indexToShow < items.length;

	function loadMore() {
		if (hasMore) {
			setIndexToShow((index) => index + limit);

			spTrackWebInteraction({
				object: "load more",
				action: "show",
				value: `${Math.min(indexToShow + limit, items.length)} events`,
			});
		}
	}

	if (items.length === 0) {
		return null;
	}

	return (
		<CardListLayout align={align}>
			<CardListLayout.Header
				tagline={tagline}
				title={title}
				subtitle={subtitle}
			/>
			<CardListLayout.Content>
				<div
					className={clsx(
						{
							"justify-center": align === "center",
						},
						{
							"flex flex-col gap-6 md:flex-row": !shouldUseGridLayout,
						},
						{
							"grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3":
								shouldUseGridLayout,
						}
					)}
				>
					{visibleEvents.map((item, index) => {
						return (
							<article
								key={index}
								className="max-w-auto flex-1 lg:max-w-[450px]"
							>
								<EventCard item={item} />
							</article>
						);
					})}
				</div>
				{hasMore ? (
					<>
						<Spacer size="layout4" />
						<div className="flex justify-center">
							<Button variant="secondary" onClick={loadMore}>
								{loadMoreText}
							</Button>
						</div>
					</>
				) : null}
			</CardListLayout.Content>
		</CardListLayout>
	);
}
