import { asText } from "~/utils/sanity-helpers";
import type {
	EventList as EventListType,
	SimplePortableText,
} from "~/types/sanity-schema";
import type { EventCardItem } from "~/components/events/event-card";
import { EventList as EventListComponent } from "~/components/eventList";
import { useSharedContent } from "~/hooks/localization";
import { formatDate, formatTime } from "~/utils/misc";
import { getCountryName } from "~/route-containers/events/country-helper";
import { getImageBuilder, getImageProps } from "~/utils/images";
import type { ImgPropsOptions } from "~/utils/images";
import type { Cta } from "~/types";
import { Icon } from "~/components/ui/icons";

interface EventItemProp extends EventCardItem {
	description?: SimplePortableText;
	endDate?: string;
	type?: string;
	seo?: { metaImage?: ImgPropsOptions };
	linkTitle?: string;
	primaryCTA?: Cta;
	slug?: { current?: string };
	contentIsPage?: boolean;
	customEventTime?: string;
	virtualEvent?: boolean;
	country?: string;
}

export function getFormattedDateTime<
	T extends { startDate?: string | null; endDate?: string | null },
>(item: T) {
	const { startDate, endDate } = item;

	if (startDate && endDate) {
		const isSameDate = formatDate(startDate) === formatDate(endDate);

		return {
			date: isSameDate
				? formatDate(startDate)
				: `${formatDate(startDate)} - ${formatDate(endDate)}`,
			time: `${formatTime(startDate)} - ${formatTime(endDate, {
				showTimeZone: true,
			})}`,
		};
	}

	return null;
}

export function EventList({
	title,
	subtitle,
	tagline,
	items,
	defaultEvents,
	limit,
	align = "center",
	showEventImage = false,
}: EventListType) {
	const { t } = useSharedContent();

	const imgOptions = (): ImgPropsOptions => {
		return {
			widths: [400, 600, 800, 1000],
			sizes: ["(min-width:768px) 40vw", "(min-width:1024px) 30vw", "100vw"],
		};
	};

	const now = new Date();

	const getItems = (): EventItemProp[] => {
		const upcomingEvents = items?.filter(
			(item) => item.endDate && new Date(item.endDate) > now
		);
		const eventItems =
			upcomingEvents && upcomingEvents?.length > 0
				? upcomingEvents
				: defaultEvents;

		if (!eventItems || eventItems.length === 0) {
			return [];
		}

		return eventItems
			.filter((item) => item.endDate && new Date(item.endDate) > now)
			.map<EventItemProp>((item) => {
				const dateTime = getFormattedDateTime(item);
				const country = item.country ? getCountryName(item.country) : "";
				const location = country
					? `${item.location}, ${country}`
					: item.location;
				const image = showEventImage
					? item.contentIsPage
						? item.seo?.metaImage
						: item.image
					: null;
				const ctaText = item.linkTitle
					? item.linkTitle
					: item.primaryCTA?.title;
				const ctaUrl =
					item.contentIsPage && item.slug?.current
						? item.slug.current
						: item.primaryCTA?.externalUrl;
				const isExternal = !item.contentIsPage && item.primaryCTA?.externalUrl;

				return {
					image: getImageProps(
						getImageBuilder(image, {
							alt: `${asText(item.title)} illustration`,
						}),
						imgOptions()
					),
					tagline: t(asText(item.type)),
					title: asText(item.title),
					subtitle: item.description,
					date: dateTime ? dateTime.date : "",
					time: item.customEventTime ? item.customEventTime : dateTime?.time,
					location: item.virtualEvent ? t("online") : location,
					cta: {
						title: ctaText,
						url: ctaUrl,
						iconRight: isExternal ? (
							<Icon name="external-link" color="primary" />
						) : null,
					},
				};
			});
	};

	const eventItems = getItems();

	return (
		<>
			<EventListComponent
				align={align}
				items={eventItems}
				title={asText(title)}
				subtitle={subtitle}
				tagline={tagline}
				limit={limit}
				loadMoreText={t("loadMore")}
			/>
		</>
	);
}
